import { get_experiments, get_users, get_num_experiments } from "../api/experiments";
import Experiment from "../models/experiment";
import { useState, useEffect, useCallback, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { isatty } from "tty";

const COUNT: number = 10;

export function usePollForExperiments() {
    const {getAccessTokenSilently, isAuthenticated, user} = useAuth0();
    const [experiments, setExperiments] = useState<Experiment[]>([]);
    const [users, setUsers] = useState<string[]>([]);

    const [userFilter, setUserFilter] = useState<string>("");
    const [userSearch, setUserSearch] = useState('');

    const [runningFilter, setRunningFilter] = useState<boolean>(false);
    const [cancelledFilter, setCancelledFilter] = useState<boolean>(false);
    const [completedFilter, setCompletedFilter] = useState<boolean>(false);
    const [failedFilter, setFailedFilter] = useState<boolean>(false);
    const [unknownFilter, setUnknownFilter] = useState<boolean>(false);
    const [startingFilter, setStartingFilter] = useState<boolean>(false);

    const [statusFilters, setStatusFilters] = useState<string|null>(null);

    const [page, setPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number | null>(null);

    const [loading, setLoading] = useState<boolean>(false);

    const currExperimentsPid = useRef<number>(0);
    const currMaxPagePid = useRef<number>(0);

    const fetchExperimentsForever = async (experimentsPid: number) => {
        const token = await getAccessTokenSilently();
        const newExperiments: Experiment[] = await get_experiments(token, {user: userFilter, name: userSearch, status: statusFilters, count: COUNT, page: page});
        if (experimentsPid !== currExperimentsPid.current) return;
        setExperiments(newExperiments);
        setLoading(false);
        setTimeout(() => {
            fetchExperimentsForever(experimentsPid);
        }, 5000)
    }

    const fetchMaxPages = async (maxPagePid: number) => {
        const token = await getAccessTokenSilently();
        const numExperiments = await get_num_experiments(token, {user: userFilter, name: userSearch, status: statusFilters});
        if (maxPagePid !== currMaxPagePid.current) return;
        const newMaxPages = Math.ceil(numExperiments / COUNT) - 1;
        setMaxPages(newMaxPages);
    }

    useEffect(() => {
        if (isAuthenticated) {
            currExperimentsPid.current += 1;
            currMaxPagePid.current += 1;
            setLoading(true);
            setPage(0);
            fetchExperimentsForever(currExperimentsPid.current);
            fetchMaxPages(currMaxPagePid.current);
        }
    }, [isAuthenticated, userFilter, userSearch, statusFilters]);

    useEffect(() => {
        if (isAuthenticated) {
            currExperimentsPid.current += 1;
            setLoading(true);
            fetchExperimentsForever(currExperimentsPid.current);
        }
    }, [isAuthenticated, page]);

    useEffect(() => {
        if (isAuthenticated) {
            if (!runningFilter && !cancelledFilter && !completedFilter && !failedFilter && !unknownFilter && !startingFilter) {
                setStatusFilters(null);
            }

            const newStatusFilters = [];
            if (runningFilter) newStatusFilters.push('running');
            if (cancelledFilter) newStatusFilters.push('cancelled');
            if (completedFilter) newStatusFilters.push('completed');
            if (failedFilter) newStatusFilters.push('failed');
            if (unknownFilter) newStatusFilters.push('unknown');
            if (startingFilter) newStatusFilters.push('starting');

            setStatusFilters(newStatusFilters.join(','));
        }
    }, [runningFilter, cancelledFilter, completedFilter, failedFilter, unknownFilter, startingFilter]);

    const fetchUsers = async () => {
        const token = await getAccessTokenSilently();
        const newUsers: string[] = await get_users(token);
        setUsers(newUsers);
    }

    useEffect(() => {
        if (isAuthenticated) fetchUsers();
    }, [isAuthenticated]);

    useEffect(() => {
      if (user?.nickname && users.includes(user.nickname)) {
        setUserFilter(user.nickname);
      }
    }, [user?.nickname, users])


    return { experiments, users, maxPages, page, setPage, userFilter, setUserFilter, userSearch, setUserSearch, loading, runningFilter, setRunningFilter, cancelledFilter, setCancelledFilter, completedFilter, setCompletedFilter, failedFilter, setFailedFilter, unknownFilter, setUnknownFilter, startingFilter, setStartingFilter}
}
