import React from 'react';
import Topbar from './Topbar';
import MainContent from './MainContent';
import { Toaster } from 'react-hot-toast';

interface DashboardProps {
  renderContent: () => React.ReactNode;
  activeTab: string;
}

const Dashboard: React.FC<DashboardProps> = ({ renderContent, activeTab }) => {
  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <Toaster position="top-right" />
      <Topbar activeTab={activeTab} />
      <MainContent>
        {renderContent()}
      </MainContent>
    </div>
  );
};

export default Dashboard;
