import React from "react";
import Experiment from "../models/experiment";
import toast from 'react-hot-toast';
import { intlFormatDistance } from "date-fns";

function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function unsecuredCopyToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Unable to copy to clipboard', err);
  }
  document.body.removeChild(textArea);
}


interface ExperimentsTableProps {
  experiments: Experiment[];
}

const ExperimentsTable: React.FC<ExperimentsTableProps> = (props) => {
  const MAX_NAME_LENGTH = 38;
  const generateWandbLink = (experiment: Experiment) => {
  const projectName = JSON.parse(experiment.config)["logging"]["project_name"];
  if (experiment.kubernetes_cluster === null && experiment.slurm_job_id !== null) {
    return `https://wandb.ai/persimmonai/${projectName}/runs/${experiment.slurm_job_id}_${experiment.name}`;
  }	else if (experiment.kubernetes_cluster !== null){
    return `https://wandb.ai/persimmonai/${projectName}/runs/${experiment.name}_klurm-${experiment.pk}-${experiment.user_name}s-train`;
    }
    return "";
  };

  const relativeTime = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto'
  });

  const generateNewRelicLink = (experiment: Experiment): string => {
    const query = `pod_name:klurm-${experiment.pk}*`;
    const data = {
      isEntitled: true,
      activeView: 'View All Logs',
      query: query,
      eventTypes: ['Log'],
    };
    const startTime = new Date(experiment.started_at.replace("T", " ") + " UTC").getTime(); // Convert to Unix timestamp in milliseconds
    const account_id = 3694342;
    const prefix = `https://one.newrelic.com/launcher/logger.log-launcher?platform${account_id}=1&launcher=`;
    const json = JSON.stringify(data);
    const encoded = btoa(unescape(encodeURIComponent(json)));  // Encoding the JSON string to base64
    const endTime = experiment.job_finish_time
    ? new Date(experiment.job_finish_time.replace("T", " ") + " UTC").getTime()
    : Date.now();
    const timeRange = `&platform[timeRange][begin_time]=${startTime}&platform[timeRange][end_time]=${endTime}`;
    return prefix + encoded + timeRange;
  };


  return (
    <table className="min-w-full">
      <thead className="bg-gray-200">
        <tr>
          <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">Started</th>
          <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">Cluster</th>
          <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
          <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
          <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">#</th>
          <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
          <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">Links</th>
          <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
          <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">Autoresume</th>
          <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider" colSpan={2}>Last Iteration</th>
        </tr>
      </thead>

      <tbody className="bg-white">
        {props.experiments.map((d, idx) => (
          <tr key={idx} className={idx % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
            <td className="px-4 py-3 text-sm font-medium text-gray-900 text-center">{new Date(d.started_at.replace("T", " ") + " UTC").toLocaleString("en-US", {timeZone: "America/Los_Angeles"})}</td>
            <td className="px-4 py-3 text-sm text-gray-900 text-center">{
              d.kubernetes_cluster === null ? (d.slurm_job_id !== null ? "slurm" : "earth") : d.kubernetes_cluster
            }</td>
            <td className="px-4 py-3 text-sm text-gray-900 text-center">{d.slurm_job_id !== null ? d.slurm_job_id : d.pk}</td>
            <td className="px-6 py-4 text-sm text-gray-900 text-left">{d.name.length > MAX_NAME_LENGTH ? d.name.substring(0, MAX_NAME_LENGTH) + "..." : d.name}</td>
            <td className="px-4 py-3 text-sm font-medium text-gray-900 text-center">{d.config !== null ? JSON.parse(d.config)["training"]["system"]["nodes"]: ''}</td>
            <td className="px-4 py-3 text-sm font-medium text-gray-900 text-center">{d.user_name}</td>
            <td className="px-4 py-3 text-sm text-gray-600  text-center">
              <a className="text-blue-800 hover:underline" href={generateWandbLink(d)} rel="noreferrer">
                  WandB
              </a>
              {" | "}
              {d.git_commit !== null ? (
                <a className="text-blue-800 hover:underline" href={`https://github.com/persimmon-ai-labs/MEGATRON-LM/commit/${d.git_commit}`} rel="noreferrer">
                    Code
                </a>
              ): "<i>Code</i>"}
              {" | "}
              {d.config !== null ? (
              <a className="text-blue-800 hover:underline" href={`/config/${d.pk}`} rel="noreferrer">
                  Config
              </a>
              ) : <i>Config</i>
            }
              {" | "}
              <a className="text-blue-800 hover:underline" href={generateNewRelicLink(d)} rel="noreferrer">
                  NewRelic
              </a>
            </td>
            <td className="px-4 py-2 text-sm text-center">
              <span className={`inline-block w-15 text-center rounded text-xs px-2 py-2 ${
                d.status === 'running' ? 'bg-custom-green' :
                d.status === 'completed' ? 'bg-blue-300' :
                d.status === 'cancelled' ? 'bg-blue-300' :
                d.status === 'failed' ? 'bg-red-300' :
                'bg-yellow-200'
              }`}>
                {d.status}
              </span>
            </td>
            <td className="px-4 py-3 text-sm text-gray-600 text-center">
              {d.next_ar_job_id ? ('✅ Done: ' + d.next_ar_job_id) : (d.autoresume ? '🔁 On' : '⤬ Off')}
             </td>
            <td className="px-4 py-3 text-sm text-gray-600 text-center">
              {d.last_iteration}
            </td>
            <td className="px-4 py-3 text-sm text-gray-600 text-center">
              <span title={ d.last_iteration_time? new Date(d.last_iteration_time.replace("T", " ") + " UTC").toLocaleString("en-US", {timeZone: "America/Los_Angeles"}) : ""}>
                { d.last_iteration_time? intlFormatDistance(new Date(d.last_iteration_time.replace("T", " ") + " UTC"), new Date()) : ""}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ExperimentsTable;
