import React from 'react';

const EvalsPage: React.FC = () => {
  return (
    <div className="w-full h-full">
      <iframe
        className="w-full h-full"
        src="https://internal.adept.ai/evals"
        title="Evals Page"
      />
    </div>
  );
};

export default EvalsPage;
