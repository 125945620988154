import React from 'react';
import { BeakerIcon, ChartBarIcon, CircleStackIcon, ComputerDesktopIcon} from '@heroicons/react/24/solid'

interface SidebarProps {
  activeTab: string;
}

const tabs = [
  { name: "Experiments", icon: BeakerIcon },
  { name: "Evals", icon: ChartBarIcon },
  { name: "Data", icon: CircleStackIcon },
];

const Topbar: React.FC<SidebarProps> = ({ activeTab }) => {
  return (
    <div className="bg-gray-100">
      <div className="flex flex-row items-center">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            className={`flex items-center text-xl p-3 text-left hover:bg-gray-300
            ${activeTab === tab.name ? "bg-gray-400" : ""}`}
            onClick={() => window.location.href = `/${tab.name.toLowerCase()}`}
          >
            <tab.icon className="h-5 w-5 mr-3" />
            {tab.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Topbar;
