import React, { useEffect, useState } from 'react';
import ExperimentsTable from './ExperimentsTable';
import { usePollForExperiments } from '../hooks/usePollForExperiments';
import { useAuth0 } from '@auth0/auth0-react';

const ExperimentsPage: React.FC = () => {
  const {isAuthenticated, loginWithRedirect} = useAuth0();
  const { experiments, users, page, setPage, maxPages, userFilter, setUserFilter, userSearch, setUserSearch, loading, runningFilter, setRunningFilter, cancelledFilter, setCancelledFilter, completedFilter, setCompletedFilter, failedFilter, setFailedFilter, unknownFilter, setUnknownFilter, startingFilter, setStartingFilter } = usePollForExperiments();

  if (!isAuthenticated) {
    return (
      <>
        <p>If you have logged in but still see this make sure to verify your account via the email sent to you!</p>
        <button
          onClick={() => {loginWithRedirect()}}
          style={{"backgroundColor": "teal", padding: "10px", "color": 'white'}}
        >Login</button>
      </>
    );
  } else {
    return (
      <div className="min-h-full min-w-full flex flex-col gap-2">
          <div className="h-fit flex flex-row items-center gap-4 mb-2">
            <select
              className="p-2 rounded-lg bg-gray-100 shadow"
              onChange={(e) => setUserFilter(e.target.value)}
              value={userFilter}
            >
              {["", ...users].map((user, idx) => (
                <option key={idx} value={user}>
                  {user || 'All Users'}
                </option>
              ))}
            </select>
            <input
              type="text"
              className="p-2 rounded-lg bg-gray-100 shadow"
              onChange={(e) => setUserSearch(e.target.value)}
              value={userSearch}
              placeholder="Name"
            />
            <div className='flex flex-row gap-2'>
              <input
                type="checkbox"
                className='p-2'
                checked={runningFilter}
                onChange={(event) => {setRunningFilter(event.currentTarget.checked)}}
              />
              <label>Running</label>
            </div>
            <div className='flex flex-row gap-2'>
              <input
                type="checkbox"
                className='p-2'
                checked={startingFilter}
                onChange={(event) => {setStartingFilter(event.currentTarget.checked)}}
              />
              <label>Starting</label>
            </div>
            <div className='flex flex-row gap-2'>
              <input
                type="checkbox"
                className='p-2'
                checked={cancelledFilter}
                onChange={(event) => {setCancelledFilter(event.currentTarget.checked)}}
              />
              <label>Cancelled</label>
            </div>
            <div className='flex flex-row gap-2'>
              <input
                type="checkbox"
                className='p-2'
                checked={completedFilter}
                onChange={(event) => {setCompletedFilter(event.currentTarget.checked)}}
              />
              <label>Completed</label>
            </div>
            <div className='flex flex-row gap-2'>
              <input
                type="checkbox"
                className='p-2'
                checked={failedFilter}
                onChange={(event) => {setFailedFilter(event.currentTarget.checked)}}
              />
              <label>Failed</label>
            </div>
            <div className='flex flex-row gap-2'>
              <input
                type="checkbox"
                className='p-2'
                checked={unknownFilter}
                onChange={(event) => {setUnknownFilter(event.currentTarget.checked)}}
              />
              <label>Unknown</label>
            </div>
          </div>
          <div className="h-full flex justify-center">
            { loading ?
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-opacity-50"></div> :
              <div className="bg-white rounded-lg shadow-lg h-fit w-full">
                <ExperimentsTable experiments={experiments}/>
              </div>
            }
          </div>
          <div className="h-fit flex items-center justify-center">
            <button
              onClick={() => setPage(prev => prev - 1)}
              disabled={page === 0 || loading}
              className={"p-2 bg-gray-100 rounded-lg shadow ml-2 disabled:opacity-50" + ((page > 0 && !loading) ? " hover:brightness-90" : "")}
            >
              Prev
            </button>
            <button
              onClick={() => setPage(prev => prev + 1)}
              disabled={page === maxPages || loading}
              className={"p-2 bg-gray-100 rounded-lg shadow ml-2 disabled:opacity-50" + ((maxPages && page < maxPages && !loading) ?  " hover:brightness-90" : "")}
            >
              Next
            </button>
          </div>
      </div>
    );
  }
};

export default ExperimentsPage;
