import React, { useEffect, useState } from 'react';
import { get_experiment } from "../api/experiments";
import Experiment from "../models/experiment";
import { useParams } from 'react-router';
// import YamlEditor from '@focus-reactive/react-yaml';
// import { oneDark } from '@codemirror/theme-one-dark';
// import CodeView from 'react-code-view';
import SyntaxHighlighter from 'react-syntax-highlighter';
import yaml from 'js-yaml';
import { useAuth0 } from '@auth0/auth0-react';

const ConfigPage: React.FC = () => {
  const [experiment, setExperiment] = useState<Experiment>();
  const { pk } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(
    () => {
      pk && getAccessTokenSilently().then(token => {
        get_experiment(token, pk).then(newExperiment => {
          setExperiment(newExperiment)
        })
      });
    }, []
  );

  return (
    <div className="p-8 h-full overflow-y-scroll">
      { experiment !== undefined ?
        // <YamlEditor json={experiment.config} theme={oneDark}/>
        // <></>
        <SyntaxHighlighter language="yaml">{yaml.dump(JSON.parse(experiment.config))}</SyntaxHighlighter>
        : <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-opacity-50"></div>
        </div>
      }
    </div>
  );
};

export default ConfigPage;
