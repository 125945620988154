import React from 'react';

const DataPage: React.FC = () => {
  return (
    <div className="w-full h-full">
      <iframe
        className="w-full h-full"
        src="https://weka.research.adept.ai"
        title="Data Page"
      />
    </div>
  );
};

export default DataPage;
