import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import './App.css';
import Dashboard from './components/Dashboard';
import ExperimentsPage from './pages/Experiments';
import EvalsPage from "./pages/Evals";
import DataPage from "./pages/Data";
import ConfigPage from "./pages/Config";
import { Auth0Provider } from "@auth0/auth0-react";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard renderContent={() => <ExperimentsPage />} activeTab='Experiments' />,
  },
  {
    path: "/experiments",
    element: <Dashboard renderContent={() => <ExperimentsPage />} activeTab='Experiments' />,
  },
  {
    path: "/evals",
    element: <Dashboard renderContent={() => <EvalsPage />} activeTab='Evals' />,
  },
  {
    path: "/data",
    element: <Dashboard renderContent={() => <DataPage />} activeTab='Data' />,
  },
  {
    path: "/config/:pk",
    element: <Dashboard renderContent={() => <ConfigPage />} activeTab='Experiments' />,
  },
]);

function App() {
  return (
    <Auth0Provider
      domain="dev-thmtl7zyoljuxzcr.us.auth0.com"
      clientId="itUm1ju3xwkeuETpH3XCSqnJUFAUWRmb"
      authorizationParams={{
        audience: "x-platform-api.research.adept.ai",
        redirect_uri: window.location.origin,
      }}
    >
     <RouterProvider router={router} />
    </Auth0Provider>
  );
}

export default App;
