import axios from 'axios';
import Experiment from '../models/experiment';
import headers from '../config.json';

const domain = 'https://x-platform-api.public.adept.ai';
// const domain = 'http://localhost:8000';

const get_experiments = async (token: string, params?: any): Promise<Experiment[]> => {
  const result = await axios.get(domain + '/experiments/', { params, headers: {"Authorization": `Bearer ${token}`} },);
  return result.data as Experiment[]
}

const get_experiment = async (token: string, pk: string): Promise<Experiment> => {
  const result = await axios.get(domain + `/experiments/experiment/${pk}`, { headers: {"Authorization": `Bearer ${token}`} });
  return result.data as Experiment;
}

const get_users = async (token: string, ) => {
  const result = await axios.get(domain + '/experiments/users', { headers: {"Authorization": `Bearer ${token}`}} );
  return result.data as string[];
}

const get_num_experiments = async (token: string, params?: any) => {
  const result = await axios.get(domain + '/experiments/count', { params, headers: {"Authorization": `Bearer ${token}`} });
  return result.data as number;
}

export {
  get_experiments, get_experiment, get_users, get_num_experiments
}
